/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import coupleOnBeach from '../../images/people/couple-on-beach.jpg'
import coupleKitchenLaptop from '../../images/people/couple-kitchen-using-laptop.jpg'
import enrollmentPeriodsInfographic from '../../images/illustrations/enrollment-periods-infographic-desktop.jpg'
import enrollmentPeriodsInfographicMobile from '../../images/illustrations/enrollment-periods-infographic-mobile.jpg'
import medicarePlansInfographic from '../../images/illustrations/medicare-plans-infographic.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Columns,
  Column,
  LinkButton,
  Link,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/QuestionsHeader'
import footer from '../../components/Footer'
import GlobalBanner from '../../components/GlobalBanner'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-indexlp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title:
            'Medicare Enrollment Information to Empower You | healthcareplans.com',
          description:
            'Learn all about Medicare enrollment including when you are eligible, when to enroll based on Medicare enrollment periods, and how to enroll depending on which plan you choose.',
          canonical: 'https://healthcareplans.com/',
          robots: 'follow,index',
        },
        path: '/resources/enrollment-overview',
        promoCode: '170009',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Healthcareplans.com | 833-316-3576| Medicare Insurance',
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <VariableContent
          backgroundColor="dark"
          className="text-align-center-desktop"
        >
          <Typography variant="h1">
            Arm Yourself with Medicare Enrollment Knowledge
          </Typography>
          <Typography variant="h5" style={{ marginBottom: '0' }}>
            Read on to learn more about Medicare eligibility, Medicare
            enrollment periods, the different parts of Medicare, and how to
            enroll.
          </Typography>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Key Takeaways</Typography>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns cardStyled>
              <Column style={{ padding: '12px 12px 0' }}>
                <StaticImage
                  src={'../../images/icons/birthday-cake.svg'}
                  alt="calendar icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons centered"
                />
                <Typography
                  variant="body"
                  style={{ textAlign: 'left', marginBottom: '0' }}
                >
                  The Initial Enrollment Period for Medicare occurs in the three
                  months before, the month of, and the three months after
                  someone’s 65th birthday.
                </Typography>
              </Column>
              <Column style={{ padding: '12px 12px 0' }}>
                <StaticImage
                  src={'../../images/icons/calendar.svg'}
                  alt="calendar icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons centered"
                />
                <Typography
                  variant="body"
                  style={{ textAlign: 'left', marginBottom: '0' }}
                >
                  If you miss your Initial Enrollment Period, sign up during the
                  General Enrollment Period each January - March.
                </Typography>
              </Column>
              <Column style={{ padding: '12px 12px 0' }}>
                <StaticImage
                  src="../../images/icons/medicine.svg"
                  alt="calendar icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons centered"
                />
                <Typography
                  variant="body"
                  style={{ textAlign: 'left', marginBottom: '0' }}
                >
                  Medicare Advantage and Part D plans from private insurers
                  provide additional coverage on top of Original Medicare.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="white"
          alignMainContent="left"
          mainContent={
            <>
              <img
                src={coupleOnBeach}
                alt="retired riding bicycles on beach"
                style={{ marginBottom: '24px' }}
              />
              <Typography variant="body">
                Whether you’re an experienced Medicare policyholder or don’t
                know your Part A from your Part B, this resource will provide
                all the information you need to know about Medicare plans,
                enrollment, and eligibility. 
              </Typography>
              <Typography variant="body">
                The technical terms and similar names of plans, enrollment
                periods (Annual Enrollment Period and Open Enrollment Period are
                often confused but entirely different, for example), and
                qualifications can be confusing. At Healthcareplans.com, we aim
                to make learning about Medicare simpler, using terms anyone can
                understand, so you can begin or continue your Medicare journey
                with ease.
              </Typography>
              <Typography variant="h2">
                When am I eligible to enroll in Medicare?
              </Typography>
              <Typography variant="body">
                The majority of Medicare enrollees become eligible for Medicare
                enrollment by age, with most Americans reaching Medicare
                eligibility at age 65. There are some exceptions,
                however—certain conditions may allow you to enroll in Medicare
                coverage before you reach 65. Below are the qualifications
                required for Medicare enrollment by age or by qualifying
                condition:
              </Typography>
              <List>
                <ListItem>
                  <strong>Qualifying by Age.</strong> If you are a citizen of
                  the United States who has been living in the U.S. for the
                  previous 5 years and are 65 years of age (or nearing your 65th
                  birthday), you are eligible for Medicare Parts A & B,
                  sometimes referred to as Original Medicare. You may qualify
                  for premium-free Medicare Part A if:
                  <List>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      you or your spouse have worked and paid Medicare taxes for
                      10 years before your age 65 year, or
                    </ListItem>
                    <ListItem>
                      you or your spouse collect Social Security or Railroad
                      Retirement Board benefits.
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <strong>Qualifying Conditions.</strong> Some disabilities may
                  qualify you for Medicare benefits even if you haven’t yet
                  turned 65. If you are disabled and have received Social
                  Security benefits for at least 24 consecutive months, you may
                  be Medicare-eligible. In addition, you qualify for early
                  benefits if:
                  <List>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      you suffer from kidney failure, also known as End-Stage
                      Renal Disease (ESRD), and have had a kidney transplant or
                      been on dialysis for 3 months, or
                    </ListItem>
                    <ListItem>
                      you have amyotrophic lateral sclerosis (ALS), also known
                      as Lou Gehrig’s disease.
                    </ListItem>
                  </List>
                </ListItem>
              </List>
              <br />
              <Typography variant="h2">
                When can I enroll in Medicare?
              </Typography>
              <img
                src={enrollmentPeriodsInfographic}
                className="show-on-desktop"
                alt="Medicare enrollment periods infographic"
                style={{ marginBottom: '24px' }}
              />
              <img
                src={enrollmentPeriodsInfographicMobile}
                className="show-on-mobile"
                alt="Medicare enrollment periods infographic"
                style={{ marginBottom: '24px' }}
              />
              <Typography variant="body">
                When you qualify for Medicare by age, there are times of the
                year when you can sign up for Medicare, known as enrollment
                periods. There are five distinct enrollment periods, explained
                in detail below.
              </Typography>
              <List>
                <ListItem>
                  <strong>Initial Enrollment Period (IEP).</strong> The Initial
                  Enrollment Period begins 3 months before the month of your
                  65th birthday and continues through your birthday month and
                  for 3 months after, a total of 7 months. During IEP, you can
                  enroll in Medicare for the first time.
                </ListItem>
                <br />
                <ListItem>
                  <strong>Annual Enrollment Period (AEP).</strong> The Annual
                  Enrollment Period runs the same time every year, from October
                  15 through December 7. During AEP, Medicare plan holders can
                  enroll in new Medicare plans, make changes to existing
                  coverage, or cancel policies. Any changes made during this
                  period take effect on January 1 of the following year.
                </ListItem>
                <br />
                <ListItem>
                  <strong>
                    Medicare Advantage Open Enrollment Period (MAOEP).{' '}
                  </strong>{' '}
                  The Medicare Advantage Open Enrollment Period is for those who
                  have already enrolled in Medicare Advantage coverage. During
                  MAOEP, which runs from January 1 through March 31 each year,
                  policyholders can add prescription drug coverage (Medicare
                  Part D) or make one more Medicare Advantage policy change. 
                </ListItem>
                <br />
                <ListItem>
                  <strong>General Enrollment Period (GEP).</strong> The General
                  Enrollment Period runs from January 1 to March 31 each year.
                  Though it runs at the same time as MAOEP, these two periods
                  should not be confused, as they serve separate purposes.
                  During GEP, you can enroll in Medicare coverage if you missed
                  your Initial Enrollment Period. Enrolling after your initial
                  period of eligibility may result in late fees, and your
                  coverage will begin July 1 of that year.
                </ListItem>
                <br />
                <ListItem>
                  <strong>Special Enrollment Period (SEP).</strong> Special
                  Enrollment Periods are triggered by certain life events—for
                  example, moving out of the coverage area for your current plan
                  or losing Medicaid eligibility. Moving into a long-term care
                  facility may also trigger a Special Enrollment Period.
                </ListItem>
                <br />
                <ListItem>
                  For a complete list of SEP-triggering events, visit{' '}
                  <Link to="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods">
                    Medicare.gov
                  </Link>
                  .
                </ListItem>
              </List>
              <br />
              <Typography variant="h2">How do I enroll in Medicare?</Typography>
              <img
                src={medicarePlansInfographic}
                className=""
                alt="Medicare plans infographic"
                style={{ marginBottom: '24px' }}
              />
              <Typography variant="body">
                The first step to enrolling in Medicare is understanding the
                different parts of Medicare coverage available and your coverage
                eligibility. The generic term “Medicare” can mean many different
                things, and encompasses a wide array of policy and coverage
                options. The following is a breakdown of when and how you can
                enroll in Medicare’s different parts.
              </Typography>
              <List>
                <ListItem>
                  <strong>Medicare Parts A & B (Original Medicare).</strong>{' '}
                  What is often referred to as “Original Medicare” comprises two
                  parts, Medicare Part A hospital and hospice coverage and
                  Medicare Part B medical insurance and preventative care.
                  Original Medicare coverage is provided by the federal
                  government.
                  <List>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      <strong>Medicare Part A signup.</strong> Whether you plan
                      to continue working and using employer-provided health
                      insurance or not, you must enroll in Medicare Part A
                      coverage during the Initial Enrollment Period surrounding
                      your 65th birthday detailed above, or you may face late
                      fees and higher premiums upon later enrollment.
                    </ListItem>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      <strong>Medicare Part B signup.</strong> When you retire,
                      enroll in Medicare Part B to complete your Original
                      Medicare coverage one of the following ways:
                    </ListItem>
                    <List className="leshen-list padding-left">
                      <ListItem className="leshen-list-item margin-bottom-0">
                        Online at{' '}
                        <Link
                          to="https://www.ssa.gov/"
                          className=""
                          target="_blank"
                        >
                          socialsecurity.gov
                        </Link>
                      </ListItem>
                      <ListItem className="leshen-list-item margin-bottom-0">
                        By phone at 1-800-772-1213
                      </ListItem>
                      <ListItem>At your local Social Security office</ListItem>
                    </List>
                  </List>
                </ListItem>
                <br />
                <ListItem>
                  <strong>Medicare Part C (Medicare Advantage).</strong>{' '}
                  Medicare Part C, sometimes referred to as Medicare Advantage,
                  is additional private-sector coverage that ensures you receive
                  all the benefits of Medicare Parts A & B, along with
                  additional benefits which may include vision coverage, dental
                  coverage, health and wellness programs, or gym memberships.
                  You can enroll in a Medicare Advantage plan:
                  <List>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      during the Initial Enrollment Period surrounding your 65th
                      birthday if you qualify for both parts of Original
                      Medicare,
                    </ListItem>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      during the Annual Enrollment Period each year from October
                      15 through December 7, or
                    </ListItem>
                    <ListItem>
                      during a Special Enrollment Period triggered by a move
                      outside your current coverage area or other qualifying
                      event.
                    </ListItem>
                  </List>
                </ListItem>
                <br />
                <ListItem>
                  <strong>Medicare Supplement (Medigap).</strong> Medicare
                  Supplement policies or Medigap policies offer additional
                  coverage for out-of-pocket Medicare costs. You must have
                  Original Medicare to enroll in a Medigap policy, and your
                  enrollment period lasts for six months after enrolling in
                  Original Medicare for the first time. Medigap policies are
                  offered by private insurance companies to supplement the
                  governmental coverage provided by Original Medicare.
                </ListItem>
                <br />
                <ListItem>
                  <strong>Medicare Part D (Prescription Drug Coverage).</strong>{' '}
                  Medicare prescription drug coverage is only available through
                  private insurer health plans purchased in addition to Original
                  Medicare and/or a Medicare Advantage plan. You can enroll in
                  or change a Part D plan:
                  <List>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      during your Initial Enrollment Period after signing up for
                      both Medicare Parts A & B,
                    </ListItem>
                    <ListItem className="leshen-list-item margin-bottom-0">
                      during the Annual Enrollment Period each year from October
                      15 through December 7, or
                    </ListItem>
                    <ListItem>
                      during the Medicare Advantage Open Enrollment Period
                      running from January 1 through March 31 each year.
                    </ListItem>
                  </List>
                </ListItem>
                <br />
              </List>
              <Typography variant="body">
                For Medicare Part C (Advantage Plans), Medigap, or Part D
                prescription drug coverage, you’ll need to work with a private
                insurance provider or an insurance agency like ours that works
                with multiple providers to connect Americans with the plans that
                fit them best. Here are just a few of the benefits of working
                with our agency to secure your Medicare coverage:
              </Typography>
              <List>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Our agents are all U.S.-based and U.S.-licensed, providing
                  quality customer service on every call.
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  Our licensed agents are medicare experts armed with the
                  knowledge to answer a wide array of questions and help nail
                  down the best possible coverage for you.
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  We offer policies from more than 6 national insurance
                  providers, so we can help find the best plan for you without
                  bias toward one particular company’s plans.
                </ListItem>
                <ListItem className="leshen-list-item margin-bottom-0">
                  We offer convenient and transparent online enrollment, so you
                  can compare plans and complete the process yourself online
                  without having to wait in lengthy phone queues.
                </ListItem>
              </List>
              <Typography variant="body">
                If you still have questions about Medicare enrollment periods or
                if you qualify for an enrollment period, call us at{' '}
                <Link to={`tel:${rotatedNumber}`} className="">
                  {rotatedNumber}
                </Link>{' '}
                to speak to a U.S.-based agent.
              </Typography>
            </>
          }
        />

        <VariableContent
          alignMainContent="left"
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Helpful resources</Typography>
              <Link to="https://www.medicare.gov/basics/get-started-with-medicare">
                https://www.medicare.gov/basics/get-started-with-medicare
              </Link>
              <Link to="https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods">
                https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan/special-enrollment-periods
              </Link>
              <Link to="https://www.medicareinteractive.org/get-answers/medicare-prescription-drug-coverage-part-d/medicare-part-d-enrollment/when-to-enroll-in-part-d ">
                https://www.medicareinteractive.org/get-answers/medicare-prescription-drug-coverage-part-d/medicare-part-d-enrollment/when-to-enroll-in-part-d 
              </Link>
              <Link to="https://www.cms.gov/medicare/health-drug-plans/medigap">
                https://www.cms.gov/medicare/health-drug-plans/medigap
              </Link>
            </>
          }
        />
        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={coupleKitchenLaptop}
              alt="an elderly couple using laptop in the kitchen and smiling"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">Who We Are</Typography>
              <Typography variant="body">
                Working with Clearlink Insurance Agency, we offer a variety of
                Medicare plan options from the nation’s top private insurance
                providers. Since 2017, we have helped more than 100,000
                Americans navigate the Medicare plan process.
              </Typography>
              <Typography variant="body">
                Our more than 300 agents are available for support and service
                as you compare and select plans online.
              </Typography>
              <LinkButton
                variant="feature"
                to="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
                color="primary"
                className="margin-x-auto"
                requestId={requestId}
              >
                Research Plans
              </LinkButton>
            </>
          }
          texta
        />

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos"
        >
          <Typography variant="h3" style={{ textAlign: 'center' }}>
            Our Insurance Brand Partners
          </Typography>
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <GlobalBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
